import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  bigNumberToNumberFromSome,
  formatCurrencyFromSome,
  formatAddressFromSome,
} from "../functions";
const ButtonLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  children,
  v,
  a,
  apis,
  addr,
  onClose,
  initialState,
  owner,
}) => {
  console.log(v);
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let { address } = acc;
    let interval = setInterval(() => {
      Promise.all([v.startPrice(), v.closed(), v.floorPrice()]).then(
        async ([sp, closed, fp]) => {
          let fSp = formatCurrencyFromSome(stdlib, sp);
          let fClosed = closed[0] === "Some" ? closed[1] : true;
          let fFp = formatCurrencyFromSome(stdlib, fp);
          let showing = fClosed ? "" : "buy";
          setState({
            // from ctc
            v,
            a,
            apis: a,
            // from acc
            acc,
            address,
            // from app
            id,
            appId: id,
            // from view
            sp: fSp,
            startPrice: fSp,
            closed: fClosed,
            fp: fFp,
            floorPrice: fFp,
            // derived
          });
        }
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);
  return React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...state, key: id, onClose });
    }
    return child;
  });
};
export default ButtonLoader;
