import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  bigNumberToNumberFromSome,
  formatCurrencyFromSome,
  formatAddressFromSome,
} from '../functions'
const PriceLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  children,
  v,
  a,
  onClose,
  initialState,
  owner,
}) => {
  const [state, setState] = useState(initialState)
  useEffect(() => {
    let { address } = acc
    let interval = setInterval(() => {
      Promise.all([
        //v.currentPrice(),
      ])
        .then(async ([
          //cp,
        ]) => {
          //let fCp = formatCurrencyFromSome(stdlib, cp)
          setState({
              // from ctc
              v,
              a,
              apis: a,
              // from acc
              acc,
              address,
              // from app
              id,
              appId: id,
              // from view
              //cp: fCp,
              //currentPrice: fCp,
              // from asset
              // from asset url (metadata)
              // derived
            })
        })
    }, 4000);
    return () => clearInterval(interval)
  }, [])
  return (
    React.Children.map(children, (child, i) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child,
          { ...state, key: id, onClose });
      }
      return child;
    })
  )
}
export default PriceLoader;