import axios from 'axios';
import apps from './apps';
const { REACT_APP_NETWORK_PROVIDER } = process.env;
const providerEnv = REACT_APP_NETWORK_PROVIDER || 'TestNet';
let auction_api_endpoint = 'https://algoapiv1.herokuapp.com';
export default {
    create: async (app, data) => {
        return (await axios.post(`${auction_api_endpoint}/api/v1/launch2`, apps[app]))?.data || 0;
    },
};
