import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  bigNumberToNumberFromSome,
  formatCurrencyFromSome,
  formatAddressFromSome,
  getAssetImage,
  getAssetTest,
  getCFIPFS,
  getUrlPath,
} from '../functions'
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
const axios = require('axios')
const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if(providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}
const getAccountTransaction = async (addr) =>
  await axios.get(`${algoexplorerapi_endpoint}/idx2/v2/accounts/${addr}/transactions`)
const auctionTitleStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const AuctionLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  children,
  ctc,
  v,
  a,
  addr,
  onClose,
  platformAddr,
  showLoading = true,
}) => {
  const [state, setState] = useState(null)
  useEffect(() => {
    Promise.all([
      v.currentPrice(),
      v.token(),
      v.closed(),
      v.endSecs(),
      v.startPrice(),
      v.floorPrice(),
      v.priceChangePerSec(),
    ])
      .then(async ([
        cp,
        tok,
        closed,
        endSecs,
        startPrice,
        floorPrice,
        priceChangePerSec
      ]) => {
        console.log({
          cp,
          tok,
          closed, 
          endSecs, 
          startPrice,
          floorPrice,
          priceChangePerSec
        })
        let fCp = formatCurrencyFromSome(stdlib, cp)
        let fFp = formatCurrencyFromSome(stdlib, floorPrice)
        let fSp = formatCurrencyFromSome(stdlib, startPrice)
        console.log({priceChangePerSec})
        let fXp = formatCurrencyFromSome(stdlib, priceChangePerSec, 10)
        let fClosed = closed[0] === 'Some' ? closed[1] : false
        let fTok = bigNumberToNumberFromSome(stdlib, tok)
        let fEndSecs = bigNumberToNumberFromSome(stdlib, endSecs)
        let transactions = (await getAccountTransaction(addr))?.data?.transactions
        let recievers = transactions
          .filter(el=>el['payment-transaction'])
          .map(el=>el['payment-transaction'].receiver)
        let isClosed = recievers.includes(platformAddr)
        const [lastBid, ...bidHistory]
          = transactions
              .filter(el => true
                && el['tx-type'] === 'pay'
                && el['payment-transaction'].receiver === addr)
              .map((el) => ({
                time: moment.unix(el['round-time']).format('LTS'),
                sender: el?.sender,
                amount: stdlib.formatCurrency(el["payment-transaction"]?.amount, 4)
              }))
              .slice(0, -3)
        let image = await getAssetImage(fTok)
        const asset = await getAssetTest(fTok)
        const { assetname, creator, total, circulatingsupply, url } = asset
        let metadata = {}
        let image_integrity
        try {
          if (url) {
            if (url.indexOf('https') === -1) {
              metadata = (await getCFIPFS(getUrlPath(url)))?.data
            } else { // maybe ipfs
              let res = (await axios.get(url))
              if (res.headers["content-type"] === "image/png") {
                image = url
              } else {
                metadata = res.data
              }
            }
          }
          image_integrity = metadata.image_integrity
        } catch (e) { }
        setState({
          showing: true,
          platformAddr,
          // from reach
          stdlib,
          // from ctc
          ctc,
          addr,
          v,
          a,
          apis: a,
          // from app
          id,
          appId: id,
          bidHistory: bidHistory.slice(0, 4),
          isClosed,
          // from view
          closed: fClosed,
          cp: fCp,
          currentPrice: fCp,
          sp: fSp,
          startPrice: fSp,
          fp: fFp,
          floorPrice: fFp,
          token: fTok,
          endSecs: fEndSecs,
          xp: fXp,
          // from asset
          creator,
          image,
          name: metadata.name || assetname,
          description: metadata.description || "",
          total,
          circulatingsupply,
          // from asset url (metadata)
          image_integrity,
        })
      })
  }, [])
  return (
    state
      ? state.showing && React.Children.map(children, (child, i) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child,
            { ...state, key: id, onClose });
        }
        return child;
      })
      : showLoading && <div style={{
        ...auctionTitleStyle,
        "fontWeight": "100",
        "color": "white",
        "marginTop": "100px"
      }}>
        <Spinner
          as="span"
          animation="grow"
          size="lg"
          role="status"
          aria-hidden="true"
        />Loading Auction...
      </div>
  )
}
export default AuctionLoader;